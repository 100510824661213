
@media screen and  (max-height: 750px){
   .subpage {
     padding: 10px 30px;
     width: 40%;
 }
 .subpagecontent {
     padding: 10px 60px 10px 47px;
     border-left: 1px solid #ddd;
     width: 60%;
 }
 .subpagedetail img {
     padding-top: 15px;
 }
 .subpage li {
     cursor: pointer;
     padding-bottom: 10px;
 }
  }
 @media screen and  (max-width: 1199px) {
  .why-choose-main{
    grid-template-columns: repeat(3, 1fr);
  }
   .middle-content p{
     margin: 0;
   }
 }
 @media screen and (max-width: 430px){
   .line-bottom:before {
     content: "";
     width: 80px;
     height: 4px;
     background: linear-gradient(to left, #ff6f6f 50%, var(--title-color) 50%);
     top: auto;
     bottom: 30%;
     position: absolute;
     margin: 0 auto;
     left: 0;
     right: 0;
 }
 .key-benifit-cad p {
   font-weight: 400;
   font-size: 14px;
   line-height: 27px;
   color: #808080;
 }
 .key-benifit-cad h5 {
   font-weight: 700;
   font-size: 18px !important;
   line-height: 29px;
   color: #2E2E2E;
 }
 }
 @media screen and (max-width: 600px) {
  .Web-Engineering h1, .mobility h1 {
    font-size: 36px !important;
}
  .Privacy-Policy-Content{padding: 40px 10px;text-align: justify;}
   .Privacy-Policy-Content h3 {
     font-size: 22px;
     font-weight: 700;
     color: var(--title-color);
     margin-bottom: 5px;
 }
 .Privacy-Policy-Content h2{
  font-size: 30px !important;
  padding-bottom: 30px;
 }
   .contact-us-form h2{
     padding-bottom: 15px;
   }
   .contact-us-form h3 {
     font-size: 24px;
     line-height: 27px;
     color: var(--title-color);
     margin-bottom: 8px;
 }
 .contact-us-left ul ul {
   padding-bottom: 20px;
 }
 .input-first-row {
   display: flex;
   column-gap: 10px;
   padding-bottom: 10px;
 }
   h1{
    font-size: 40px !important;
   }
   h2{
     font-size: 32px !important;
   }
   .main-banner{
     width: 100%;
   }
   .banner-bg{
     width: 89%;
   }
   .banner-content{
     padding-bottom: 20px;
   }
   .card-grid{
     width: 50%;
     padding: 10px 10px;
   }
   .card{
     /* height: 250px; */
     /* flex-direction: column; */
     /* justify-content: center; */
     /* padding: 0 10px; */
     /* text-align: center; */
   }
   .card-text{
     font-size: 14px;
     line-height: 100%;
   }
   .card-title{
     font-size: 18px;
     line-height: 22px;
   }
   .card-detail ul{
     padding-left: 0;
   }
   .left-counter-card, .right-counter-card{
     width: 100%;
     height: 160px;
     padding: 10px;
   }
   .left-counter-card h3, .right-counter-card h3{
     font-size: 42px;
   }
   .left-counter-card p, .right-counter-card p{
     font-size: 16px;
     line-height: 20px;
   }
   .count-card {
     padding-bottom: 30px;
   }
   .swiper-button-next, .swiper-rtl .swiper-button-prev {
     right: 0% !important;
 }
 .swiper-button-prev, .swiper-rtl .swiper-button-next {
   right: 15% !important;
   left: auto !important;
 }
 .client-slider{
   padding: 20px;
 }
 .client-slider ul{
   padding-top: 10px;
 }
 .marquee-slider {
   width: 280px;
   height: 300px;
 }
 .marquee-slide ul{
   padding-left: 10px;
 }
 .about-industries{
   font-size: 14px;
   line-height: 115%;
 }
 .marquee-img img {
   max-width: 50px;
 }
 .technology .react-tabs ul li{
   width: calc(100% / 2.5);
   font-size: 14px;
 }
 .m-active:after {
   content: '';
  display: none;
 }
 .react-tabs__tab-panel {
   margin-top: 20px;
 }
 .technology-pan img {
   width: 40px;
   height: 40px;
 }
 .technology-pan{
   width: 25%;
 }
 .project-carosoul{
   position: static;
   transform: none;
   width: 100%;
 }
 .position-class{
   height: auto;
   padding-bottom: 30px;
 }
 .carosoul-control h2 {
   text-align: center;
   padding-bottom: 20px;
 }
 .carosoul-control p {
   text-align: center;
 }
 .project .swiper-button-next {
   top: auto !important;
   right: auto !important;
   z-index: 9;
   bottom: 10px !important;
   right: 35% !important;
 }
 .project .swiper-button-prev {
   top: auto !important;
   right: auto !important;
   z-index: 9;
   bottom: 10px !important;
   left: 35% !important;
 }
 section {
   padding: 20px 0;
   overflow-x: hidden;
 }
 .why-choose-text li {
   font-weight: 600;
   font-size: 18px;
   line-height: 26px;
 }
 .footer{
   padding: 0;
 }
 .footer .container{
   margin:  0 !important;
 }
 .footer .logo{
   margin: auto !important;
   padding-bottom: 20px;
 }
 .footer h6,.footer p{
   margin: 0 !important;
 }
 
 }
 @media screen and (max-width: 500px){
   .BlogDetail .comment {
     width: calc(100% - 50px);
     margin-left: 20px;
 }
 .BlogDetail .comment-img {
   width: 100px;
   height: 100px;
   border-radius: 50%;
 }
 .technology-pan {
   text-align: center;
   width: 25% !important;
 }
 }
 @media screen and (max-width: 1400px){
   .technology .react-tabs ul li {
     font-size: 16px;
 }
 }
 @media screen and (max-width: 1280px){
   .technology .react-tabs ul li {
     width: calc(100% / 7);
 }
 .technology .react-tabs ul li {
   font-size: 14px;
 }
 }
 @media screen and (max-width: 767px){

  .counter-box p {
    font-size: 14px;
}
.counter-box .counter-item:after{
  display: none;
}
  footer .row{
    row-gap: 0;
  }
  .row{
    row-gap: 40px;
  }
   .project-carosoul{
     position: static;
     transform: none;
     width: 100%;
   }
   .position-class{
     height: auto;
     padding-bottom: 30px;
   }
   .carosoul-control h2 {
     text-align: center;
     padding-bottom: 20px;
   }
   .carosoul-control p {
     text-align: center;
   }
   .project .swiper-button-next {
     top: auto !important;
     right: auto !important;
     z-index: 9;
     bottom: 45px !important;
     right:calc(50% - 66px) !important;
     transform: translate(-50% , 0);
   }
   .project .swiper-button-prev {
     top: auto !important;
     right: auto !important;
     z-index: 9;
     bottom: 45px !important;
     left: calc(50% - 26px) !important;
     transform: translate(-50% , 0);
   }
   .technology-pan {
     text-align: center;
     width: 20%;
 }
   .BlogDetail .Author-Detail, .BlogDetail .Social-Icon {
     width: 100%;
 }
   .author-Detail-Block{
     display: block !important;
   }
   .privacy-benner{
     flex-direction: column-reverse;
   }
   .mask-group{
     text-align: center;
     padding-top: 25px;
   }
   .specific-position-row {
     flex-direction: column;
     justify-content: center;
 }
   .mission-pan-mobile{display: block;}
   .AboutCard{
     height: 286px;
   }
   .mission-vission .react-tabs__tab-list ,.mission-vission .react-tabs__tab-panel{
     width: 100%;
 }
 .mission-vission .react-tabs {
   display: flex;
   column-gap: 0px;
   flex-direction: column;
 }
 .mission-vission .react-tabs__tab-list{
   display: flex;
     flex-wrap: wrap;
     justify-content: center;
 }
 .mission-pan mission-pan-mobile{
   display: block;
 }
 .CoreValues .react-tabs{
   display: none;
 }
 .OurAssociates img{
   width: 50%;
 }
 }
 
 @media screen and (min-width: 768px) and (max-width: 1280px) {
  section{
   overflow-x: hidden;
  }
  h1{
   font-size: 40px;
  }
  h2{
    font-size: 32px;
  }
  .main-banner{
    width: 100%;
  }
  .banner-bg{
    width: 89%;
  }
  .card-grid{
   width: 50%;
   padding: 10px 10px;
 }
 .left-counter-card, .right-counter-card{
   width: 100%;
   height: 160px;
   padding: 10px;
 }
 .left-counter-card h3, .right-counter-card h3{
   font-size: 42px;
 }
 .left-counter-card p, .right-counter-card p{
   font-size: 16px;
   line-height: 20px;
 }
 .technology-pan{
   width: 20%;
 }
 }
 
 
 
 @media screen and (min-width: 912px) and (max-width: 1280px) {
   .project-carosoul{
     left: 10px;
   }
  }
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
   .project-carosoul{
     left: 10px;
   }
   section {
     padding: 40px 0;
     overflow-x: hidden;
   }
  }
  @media screen and  (min-width: 992px){
   
  .main-menu:hover .Submenu{
   display: flex;
 }
 .main-menu:hover .carret{
   transform: rotate(180deg);
 }
  }
  @media screen and  (max-width: 1440px){
   .Real-Time-Journey img {
     right: -24%;
 }
  }
  @media screen and  (max-width: 768px){
    .portfolio-section .swiper-slide:hover .portfolio-detail {
      bottom: -50%;
      transition: 0.4s ease-out;
  }
  .portfolio-section .swiper-slide:hover {
    transform: translateY(0px);
    transition: 0.4s ease-out;
}
   .how-it-work-row h4 {
     font-weight: 700;
     font-size: 28px;
     line-height: 32px;
     color: #2E2E2E;
     padding-bottom: 20px;
 }
 .how-it-work-row h3 {
   font-weight: 300;
   font-size: 36px;
   line-height: 40px;
   color: var(--common-color);
   padding-bottom: 20px;
 }
 .how-it-work-row img{
   width: 60%;
 }
 .how-it-work-row{
   padding: 30px 0;
 }
   .Web-Engineering .banner-img{
     display: none;
   }
   .Web-Engineering{
     background: linear-gradient( 94.52deg, rgba(255, 224, 224, 0.5) 0%, rgba(255, 241, 241, 0.5) 100% );
     padding-bottom: 20px;
   }
  }
  @media screen and  (max-width: 991px) {
    .indusries-card{
      width: calc(50% - 10px) !important;
  }
    .counter-box .counter-item {
      padding: 0 25px;
  }
   .technology .react-tabs__tab--selected:after {
     content: '';
     position: absolute;
     left: 50%;
     bottom: -150%;
     transform: translate(-50%, 0);
     z-index: 9;
     border-width: 0 57px 57px 57px;
     border-color: transparent transparent #ffffff transparent;
     border-style: solid;
     visibility: hidden;
 }
   .technology .react-tabs ul li {
     width: calc(100% / 4);
 }
 .technology .react-tabs ul li {
   font-size: 13px;
     line-height: 15px;
 }
   section {
     padding: 25px 0;
     overflow-x: hidden;
   }
   .About-Us-main .line-bottom:before {
     content: "";
     width: 80px;
     height: 4px;
     background: linear-gradient(to left, #ff6f6f 50%, var(--title-color) 50%);
     position: absolute;
     margin: 0 auto;
     top: auto;
     left: 0;
     right: 0;
     bottom: 25%;
 }
   .About-Counter-Details h3 {
     font-weight: 700;
     font-size: 32px;
     line-height: 46px;
     color: var(--common-color);
 }
 .mission-Absolute{
   display: none;
 }
 .About-Counter-Details img {
   width: 170px;
   height: 170px;
 }
 .About-Counter-Details {
   height: 300px;
 }
   .Real-Time-Journey img {
     right: -38%;
 }
   .subpagecontent{
     display: none;
   }
   .subpage {
     padding: 0;
     width: 100%;
 }
 .Submenu {
   background-color: none;
   padding: 0;
   border-radius: 10px;
   box-shadow: none;
   position: static;
   left: 50%;
   width: auto;
   transform: none;
   background-color: transparent;
 }
 .subpage li{
   padding-bottom: 10px;
 }
 .navbar.open{
   height: 100vh;
   overflow-y: scroll;
   overflow-x: hidden;
   background: linear-gradient(to top, #fff, #f0efff);
 }
 .my-class .navbar.open{
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(to top, #0B082D, #130f3a);
}
 .navbar {
   position: relative;
   display: block !important;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
 }
 .nav-link{
   padding: 12px 0 !important;
 }
 .Submenu:before {
   display: none;
 }
 .display{
   display: flex;
 }
 .carretrotate{
   transform: rotate(180deg);
 }
 .key-benifit-cad h5 {
   font-weight: 700;
   font-size: 23px;
   line-height: 29px;
   color: #2E2E2E;
 }
 .industriesserve .indusries-card p {
   padding-top: 20px;
   font-weight: 600;
   font-size: 18px;
   line-height: 27px;
   color: #2E2E2E;
 }
 .industriesserve img {
   height: 80px;
   width: 80px;
 }
 .Web-Engineering .banner-bg {
   animation: none;
   top: 0;
   left: 55%;
   transform: translate(-50% , 0);
   width: 100%;
 }
 .job-position-data{
   width: 47%;
   align-content: center;
   flex-direction: column;
 }
 .job-type{
   text-align: center;
 }
 .main-job-position {
   column-gap: 3%;
 }
 .job-location ,.job-duration,.job-experince{
   display: flex;
 }
 .job-location .location-text,.job-duration .duration-text,.job-experince .experince-text{
   padding-left: 10px;
   font-weight: 400;
 }
 .job-location p,.job-duration p,.job-experince p{
   font-weight: 600;
 }
  }
  @media screen and  (max-width: 575px){
    .ar-overview {
      gap: 10px;
  }

    .main-content {
      padding-bottom: 55px;
  }

    .technology-slide h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
  }
   .job-position-data{
     width: 100%;
   }
   .privacy-mobile-sub h2{
    display: none;
   }
   .About-us h1.line-bottom:before {
      content: "";
      width: 80px;
      height: 4px;
      background: linear-gradient(to left, #ff6f6f 50%, var(--title-color) 50%);
      bottom: 39px;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
  }
  }
  @media screen and  (max-width: 1440px){
    .Web-Engineering, .banner-sec.mobility {
      padding-bottom: 87px;
      padding-top: 138px;
  }
   .why-choose-text li {
     font-size: 14px;
     line-height: 20px;
 }
   h2 {
     font-size: 32px ;
     line-height: 100% ;
 }
 h1 {
   font-size: 48px ;
   line-height: 120% ;
 }
  }
  @media screen and  (max-width: 1399px){
   .mission-vission .react-tabs__tab-list .react-tabs__tab--selected:after {
     right: -42%;
 }
    }
  @media screen and  (max-width: 1199px){
    .section.data-count{
      margin-top: 0;
      padding-top: 50px;
    }
    .Web-Engineering h1, .mobility h1 {
      /* font-size: 48px; */
  }
    .swiper-slide:nth-child(even) .portfolio-card img {
      border-radius: 0 110px 0px 0px;
  }
  .swiper-slide:nth-child(odd) .portfolio-card img {
    border-radius: 110px 0px 0px 0px;
}
.portfolio-card {
  width: 250px;
  height: 330px;
}
.portfolio-detail {
  width: 250px;
  bottom: -50%;
}
.leftcolaborotors {
  top: 15%;
  width: 60%;
  left: -35px

}
 .mission-vission .react-tabs__tab-list .react-tabs__tab--selected:after {
   right: -50%;
 }
  }
  @media screen and  (max-width: 1024px){
    .indusries-card span {
      font-size: 14px;
      line-height: 18px;
  }
    .overview-item {
      width: 180px;
      height: 140px;
  }
  .overview-item h3 {
    font-size: 32px;
    line-height: 42px;
}
   .why-choose-text li {
     font-size: 14px;
     line-height: 20px;
 }
   .client-slider {
     padding: 10px 20px;
 }
   .project-name {
     font-size: 24px;
 }
 .project-slide{
   padding: 20px;
 }
 .project-slide li{
   padding: 2px 4px;
 }
 .technology .react-tabs__tab-panel {
   margin-top: 50px;
 }
  }
  @media screen and  (max-width: 991px){
    .contact-form-iframe {
      padding: 0 6px 10px 6px;
  }
    .contact-form-detail {
      padding: 16px;
  }
    .case-item h3 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 12px;
  }
  .case-item p {
    font-size: 14px;
    line-height: 18px;
}
.case-item ul li {
  padding: 10px 10px;
}
    .why-choose-main{
      grid-template-columns: repeat(2, 1fr);
    }
   .quote {
     font-size: 12px;
     width: 120px;
     margin-top: 5px;
     margin-bottom: 5px;
 }
  } 

  @media screen and  (max-width: 767px){
    .web-development-needs {
      padding-top: 25px;
  }
  .top-block {
    margin-top: 0px;
}
    .ar-overview {
      justify-content: center;
  }
    .Web-Engineering h1, .mobility h1 {
      font-size: 36px;
  }
  .Web-Engineering .txt-with-bg{
    margin: 20px 0 20px 20px;
  }
   .card-grid {
     width: 100%;
     padding: 10px 10px;
 }
  
   .project-carosoul {
     width: 100%;
 }
 .mission-vission .react-tabs__tab-list .react-tabs__tab--selected:after{
   display: none;
 }
  }
  @media screen and (max-width:700px){
    .ARVR-client {
      box-shadow: 15px 27px 54px rgba(0, 0, 0, 0.21);
      position: relative;
      width: 300px;
      height: 440px;
      margin: 10px;
      padding: 25px;
  }
  }
  @media screen and (max-width:430px){

  }

  /* aboutus  */
  @media (min-width:600px) {
    .about-us-new h1 {
        font-size: 4.75rem;
        line-height: 4.75rem;
        letter-spacing: -2px;
        padding-bottom: 48px;
    }
    .centered-content h2{
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -2px;
    }
    .centered-content p{
      font-size: 1.125rem;
        line-height: 1.575rem;
    }
    .iabout-company h3{
      font-size: 0.875rem;
        line-height: 1.3125rem;
    }
    .about-company h4{
      font-size: 1.6875rem;
      line-height: 2.025rem;
      letter-spacing: -1px;
    }
    .about-company p{
      font-size: 1.125rem;
      line-height: 1.575rem;
    }
    .join-team h5{
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -2px;
    }
    .join-team p{
      font-size: 1.125rem;
      line-height: 1.575rem;
    }
    .company-team span{
      font-size: 0.875rem;
        line-height: 1.3125rem;
    }
    .company-team h6{
      font-size: 1.6875rem;
      line-height: 2.025rem;
      letter-spacing: -1px;
    }
}

@media (min-width:1312px) {
  .about-us-new h1{
        font-size: 6.5rem;
        line-height: 6.5rem;
        letter-spacing: -4px;
    }
    .centered-content h2{
      font-size: 4rem;
        line-height: 4.4rem;
        letter-spacing: -2px;
    }
    .centered-content p{
      font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .about-company h3{
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
    .about-company h4{
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -1px;
    }
    .about-company p{
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    .join-team h5{
      font-size: 4rem;
      line-height: 4.4rem;
      letter-spacing: -2px;
    }
    .join-team p{
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    .company-team span{
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
    .company-team h6{
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -1px;
    }
}
@media (min-width:950px) {
  .centered-content p {
      max-width: 950px;
      margin: auto;
  }
}
@media (min-width: 0px) and (max-width:575px) {
  .col-xs-6 {
      flex: 0 0 auto;
      width: 50% !important;
  }
  .why-choose-main{
    grid-template-columns: repeat(1, 1fr);
  }
  .partner-grid{
    grid-template-columns: repeat(2, 1fr);
  }
  .overview-item{
    width: 140px;
    height: 140px;
    padding: 10px;
  }
  .industriesserve .indusries-card p{
    margin-bottom: 0;
    padding-top: 10px;
  }
  .industriesserve .indusries-card{
    padding: 10px;
  }
}